import { useState } from "react";

const TransactionProof = () => {
    const [proofs, setProofs] = useState([])
    return (
        <section id="transaction">
            <p className='text-center fw-bold'>
                <span className='section-title resp-h4'>
                <i class="bi bi-ui-checks"></i> &nbsp;
                    Transaction Proof
                </span>
            </p>
            {
                !(!!proofs.length) && <p className="text-center">No transaction proofs, updating soon...</p>
            }
        </section>
    );
}
 
export default TransactionProof;