import Header from './layouts/Header';
import Main from './layouts/Main';

function App() {
  return (
      <>
        <Header/>
        <Main />
      </>
  );
}

export default App;
